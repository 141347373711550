<template>
  <div class="p-grid">
    <div class="p-col-12">
      <h1>Not implemented</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
};
</script>
